export default class BTableConfigChecking<T> {
    public rows: T[];
    public header: boolean;
    constructor() {
        this.rows = [];
        this.header = true;
    }
    public check!: (list: T[], row: T) => void;
    public checkAll!: (list: T[]) => void;
    public isCheckableRow(row: Object): boolean { 
        return true;
    };
}