import BTableColumn from '@/models/base/BTableColumn.model';
import moment from 'moment';
import { BTableColumnType } from '@/enums/BTableColumnType';
import { RowTable } from '@/typings/RowTable';
export default class BTableColumnFilter{
    static Value(value: any, column: BTableColumn, props: RowTable<object>){
        if (value === undefined || value === null || value === String())
            return process.env.VUE_APP_EMPTY_TEXT || '';

        if (column.type === BTableColumnType.Date)
            return moment(value).format('L');

        if (column.type === BTableColumnType.Time)
            return moment(value).format('LT');
            
        if (column.type === BTableColumnType.Bool)
            return value === true ? 'Sí' : 'No';
            
        if (column.type === BTableColumnType.Custom)
            return column.customFilter ? column.customFilter(value) : value;

        return value;
    }
}