import BaseEntity from '../base/BaseEntity.model';
import AppUserRecoveryQuestion from './appUserRecoveryQuestion.model';

export default class User extends BaseEntity{
    public  adid ?: | null
    public  name ?: | null
    public  surName ?: | null
    public  email ?: | null
    public  secondEmail ?: string | null
    public  phoneRecovery ?: string;
    public appUserRecoveryQuestions?:any;
    constructor(){
        super();
        this.init();
    }

    init(){
        this.adid = null;
        this.name = null;
        this.surName = null;
        this.email = null;
        this.secondEmail = "";
        this.phoneRecovery = "";
        this.appUserRecoveryQuestions = [];
    }
}