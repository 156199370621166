import BaseApi from '@/core/HttpResources/BaseApi';

export default class SDSADConnectorService {
    async getApplications() {
        var baseApi = new BaseApi("SDSADConnector/applications");
        return new Promise<any>((resolve, reject) => {
            return baseApi.request(
                "GET",
                String(),
                null,
                {},
                response => resolve(response.data),
                reject,
                () => { }
            )
         }
        );
    }
    async getOrganization() {
        var baseApi = new BaseApi("SDSADConnector/ActiveOrganizationADAdminConnector");
        return new Promise<any>((resolve, reject) => {
            return baseApi.request(
                "GET",
                String(),
                null,
                {},
                response => resolve(response.data),
                reject,
                () => { }
            )
         }
        );
    }
    async getQuestions() {
        var baseApi = new BaseApi("SDSADConnector/questions");
        return new Promise<any>((resolve, reject) => {
            return baseApi.request(
                "GET",
                String(),
                null,
                {},
                response => resolve(response.data),
                reject,
                () => { }
            )
         }
        );
    }
    async getApplicationsByCriteria(name:string) {
        var baseApi = new BaseApi("SDSADConnector/GetApplicationsByCriteria/"+ name);
        return new Promise<any>((resolve, reject) => {
            return baseApi.request(
                "GET",
                String(),
                null,
                {},
                response => resolve(response.data),
                reject,
                () => { }
            )
         }
        );
    }
    async getApplicationsOrderByName() {
        var baseApi = new BaseApi("SDSADConnector/GetApplicationsOrderByName");
        return new Promise<any>((resolve, reject) => {
            return baseApi.request(
                "GET",
                String(),
                null,
                {},
                response => resolve(response.data),
                reject,
                () => { }
            )
         }
        );
    }
    async getActiveSmtp() {
        var baseApi = new BaseApi("SDSADConnector/GeActiveSmtp");
        return new Promise<any>((resolve, reject) => {
            return baseApi.request(
                "GET",
                String(),
                null,
                {},
                response => resolve(response.data),
                reject,
                () => { }
            )
         }
        );
    }

    async getActiveTwilio() {
        var baseApi = new BaseApi("SDSADConnector/GetActiveTwilio");
        return new Promise<any>((resolve, reject) => {
            return baseApi.request(
                "GET",
                String(),
                null,
                {},
                response => resolve(response.data),
                reject,
                () => { }
            )
         }
        );
    }
    async getApplicationsOrderByAcronym() {
        var baseApi = new BaseApi("SDSADConnector/GetApplicationsByAcronym");
        return new Promise<any>((resolve, reject) => {
            return baseApi.request(
                "GET",
                String(),
                null,
                {},
                response => resolve(response.data),
                reject,
                () => { }
            )
         }
        );
    }

    async getApplicationsOrderByRecently() {
        var baseApi = new BaseApi("SDSADConnector/GetApplicationsByRecently");
        return new Promise<any>((resolve, reject) => {
            return baseApi.request(
                "GET",
                String(),
                null,
                {},
                response => resolve(response.data),
                reject,
                () => { }
            )
         }
        );
    }
}