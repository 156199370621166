import Vue from 'vue';
import { default as Adal, AxiosAuthHttp, AuthenticationContext } from 'vue-adal'
import { RouteConfig } from 'vue-router';
import router, { routesNotAuthenticated } from '@/router';
import config from "./constants";
import Axios from 'axios'
import HttpStatusCode from '@/enums/HttpStatusCode';
import UserService from '../../services/user.service';
import store from "@/store"
var userService: UserService = new UserService();
const route = router as any;

const currentLocation: string = route.history._startLocation.substring(1);
const indexOfSlash = currentLocation.includes("/") ? currentLocation.indexOf("/") : undefined;
const currentPage = currentLocation.substring(0, indexOfSlash);
const isAnonymosRoute = routesNotAuthenticated.some((x: RouteConfig) => x.path.includes(currentPage))

Vue.use(Adal, {
    config: {
        tenant: config.tenant,
        clientId: config.clientid,
        redirectUri: config.redirectUri,
        cacheLocation: config.cacheLocation
    },
    requireAuthOnInitialize: false,
    router: router
})

const token = localStorage.getItem("adal.idtoken");
const AADSTS50058 = 'AADSTS50058';
const AADSTS65001 = 'AADSTS65001'
const AADSTS16000 = 'AADSTS16000';

if (token) {
    Axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
} else {
    AuthenticationContext.acquireToken(config.clientid, (err: any, token: string) => {
        if (err) {
            let errCode = err.split(':')[0]
            switch (errCode) {
                case AADSTS50058: // Need to prompt for user sign in
                    if (!isAnonymosRoute) 
                        AuthenticationContext.login();
                    break
                case AADSTS65001: // Token is invalid; grab a new one
                    AuthenticationContext.acquireTokenRedirect(config.clientid);
                    break
                case AADSTS16000: // No Access
                default: // Need a pop-up forcing a login
                    if (!isAnonymosRoute) 
                        AuthenticationContext.login();
                    break
            }
            return
        }
        Axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    });
}


Axios.get(process.env.VUE_APP_API_URL + 'User/Current').then(res => {
    store.commit("set_user", res.data)
})

Axios.interceptors.response.use(response => Promise.resolve(response), error => {
    if (error && error.response && error.response.status === HttpStatusCode.UNAUTHORIZED) {
        AuthenticationContext.login();
    }
    throw error;
})

Vue.prototype.$http = Axios;
