

























































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import UserService from "@/services/user.service";
import BaseVue from "@/core/BaseVue";
import { AuthenticationContext } from "vue-adal"; // This will be populated with a valid context after initialization
@Component({})
export default class ChangePasswordComponent extends BaseVue {
  @Prop() readonly show!: boolean;

  c_show = this.show != undefined ? this.show : false;
  userService = new UserService();

  newPassword: string | null = null;
  repeatnewPassword: string | null = null;
  loading = false;
  messageError = "";

  get userProfile() {
    return AuthenticationContext.user
      ? AuthenticationContext.user.profile
      : null;
  }

  @Watch("show")
  onShowChange() {
    this.c_show = this.show;
  }
  hide() {
    this.$emit("update:show", false);
    this.$emit("hide");
    this.repeatnewPassword = null;
    this.newPassword = null;
    this.messageError = "";
    this.c_show = false;
  }

  cancel() {
    this.hide();
  }

  async updatePassword() {
    try {
      var result = await this.$validator.validateAll();
      if (result) {
        this.loading = true;
        this.userService
          .changePassword(this.newPassword as string)
          .then(() => {
            this.operationSuccess();

            this.hide();
            setTimeout(() => this.$validator.errors.clear(), 10);
          })
          .catch((x) => {
            this.messageError = x.response.data.error.message;
            this.operationFailed(x.response.data.error.message, 5000);
          })
          .finally(() => {
            this.loading = false;
          });
        return;
      }
      throw this.$validator.errors.items.map((e) => e.msg);
    } catch (errors) {
      this.operationFailedErrors(errors);
    }
  }
}
