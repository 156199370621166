import BaseApi from '@/core/HttpResources/BaseApi';
import { Utils } from '../core/Utils';
import User from '../models/security/user.model';
import ChangePassword from '@/components/password/changePassword.vue';

export default class UserService {
    async changePassword(newPassword: string) {
        var baseApi = new BaseApi("User/Me/ChangePassword");
        return new Promise<any>((resolve, reject) => {
            return baseApi.request(
                "POST",
                String(),
                { newPassword: newPassword },
                {},
                response => resolve(response.data),
                reject,
                () => { }
            )
        }
        );
    }
    async changeUserPassword(userId:string,newPassword: string) {
        var baseApi = new BaseApi(`User/${userId}/ChangePassword`);
        return new Promise<any>((resolve, reject) => {
            return baseApi.request(
                "POST",
                String(),
                { newPassword: newPassword },
                {},
                response => resolve(response.data),
                reject,
                () => { }
            )
        }
        );
    }
    
    async saveRecoveryQuestion(qustionAnswered:any) {
        var baseApi = new BaseApi(`User/RecoveryQuestion`);
        return new Promise<any>((resolve, reject) => {
            return baseApi.request(
                "POST",
                String(),
                qustionAnswered ,
                {},
                response => resolve(response.data),
                reject,
                () => { }
            )
        }
        );
    }
    async updateRecoveryQuestion(qustionAnswered:any) {
        debugger
        var baseApi = new BaseApi(`User/UpdateAnswers`);
        return new Promise<any>((resolve, reject) => {
            return baseApi.request(
                "Put",
                String(),
                qustionAnswered,
                {},
                response => resolve(response.data),
                reject,
                () => { }
            )
        }
        );
    }
    async getWaysToRecoveryPasswordByEmail(email: string) {
        var baseApi = new BaseApi(`User/Recovery/${email}/Password`);
        return new Promise<any>((resolve, reject) => {
            return baseApi.request(
                "GET",
                String(),
                null,
                {},
                response => resolve(response.data),
                reject,
                () => { }
            )
        }
        );
    }
    async getUserQuestion(email: string) {
        var baseApi = new BaseApi(`User/Question/${email}`);
        return new Promise<any>((resolve, reject) => {
            return baseApi.request(
                "GET",
                String(),
                null,
                {},
                response => resolve(response.data),
                reject,
                () => { }
            )
        }
        );
    }
    async createAGeneratedCode(email: string) {
        var baseApi = new BaseApi(`User/CreateCode/${email}`);
        return new Promise<any>((resolve, reject) => {
            return baseApi.request(
                "GET",
                String(),
                null,
                {},
                response => resolve(response.data),
                reject,
                () => { }
            )
        }
        );
    }
    async getCurrentUser() {
        debugger;
        var baseApi = new BaseApi("User/Current");
        return new Promise<any>((resolve, reject) => {
            return baseApi.request(
                "GET",
                String(),
                null,
                {},
                response => resolve(response.data),
                reject,
                () => { }
            )
         }
        );
    }
    async getGenerateCode(virificationCode:string) {
        var baseApi = new BaseApi("User/GetGenerateCode/"+virificationCode);
        return new Promise<any>((resolve, reject) => {
            return baseApi.request(
                "GET",
                String(),
                null,
                {},
                response => resolve(response.data),
                reject,
                () => { }
            )
         }
        );
    }

    async validatinGenerateCode(generateCode:string) {
        var baseApi = new BaseApi("User/GenerateCode/"+generateCode);
        return new Promise<any>((resolve, reject) => {
            return baseApi.request(
                "GET",
                String(),
                null,
                {},
                response => resolve(response.data),
                reject,
                () => { }
            )
         }
        );
    }
    
  
    async sendEmail(secondEmail:string,email:string) {
        var baseApi = new BaseApi("User/Send/"+secondEmail +"/"+email);
        return new Promise<any>((resolve, reject) => {
            return baseApi.request(
                "POST",
                String(),
                null,
                {},
                response => resolve(response.data),
                reject,
                () => { }
            )
         }
        );
    }

    async sendSms(phone:string) {
        var baseApi = new BaseApi("User/SendSms/"+ phone);
        return new Promise<any>((resolve, reject) => {
            return baseApi.request(
                "POST",
                String(),
                null,
                {},
                response => resolve(response.data),
                reject,
                () => { }
            )
         }
        );
    }

    async update(id:any,model:User) {
        var baseApi = new BaseApi("User/" +id);
        return new Promise<any>((resolve, reject) => {
            return baseApi.request(
                "PUT",
                String(),
                model,
                {},
                response => resolve(response.data),
                reject,
                () => { }
            )
         }
        );
    }
}