














































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src
import { AuthenticationContext } from "vue-adal"; // This will be populated with a valid context after initialization
import ApplicationComponent from "../modules/applications/applicationComponent.vue";
import User from "@/models/security/user.model";
import UserService from "@/services/user.service";
import BaseVue from "../core/BaseVue";
import SDSADConnectorService from "../services/sdsadConnector.service";
import AppUserRecoveryQuestion from "../models/security/appUserRecoveryQuestion.model";
import { Utils } from "@/core/Utils";
@Component({
  components: {
    HelloWorld,
    ApplicationComponent,
  },
})
export default class Home extends BaseVue {
  public userService: UserService = new UserService();
  public sdsadConnectorService: SDSADConnectorService = new SDSADConnectorService();
  public isRecoveryAcountActive: boolean = false;
  public model: User = new User();
  public comunicateThrougEmailOrPhone: boolean = false;
  public questionAnswered: Array<AppUserRecoveryQuestion> = [];
  questions: any = [];
  public isLoading: boolean = false;
  isFirsTime = true;
  currentTab: number = 0;
  isAuthMethodQuestionActive = false;
  secondTime = true;

  async mounted() {
    //debugger;
    this.isLoading = true;
    await this.getQuestions();
    this.getOrganization();
    this.isLoading = false;
  }

  onCurrentTabChange(value: number) {
    this.currentTab = value;
  }
  saveAnswer(question: string, questionId: number, isRequired: boolean) {
    let item = new AppUserRecoveryQuestion();
    let inputValue: any;
    let objectFinded = this.questionAnswered.find(
      (x) => x.questionId == questionId
    );

    let inputId = "input_id" + questionId;
    inputValue = document.getElementById(inputId);
    let value = inputValue.value;

    if (objectFinded) {
      if (value) {
        objectFinded.answer = value;
        let index = this.questionAnswered.findIndex(
          (p) => p.questionId == questionId
        );
        this.questionAnswered.splice(index, 1);
        this.questionAnswered.push(objectFinded);
      }
    } else {
      item.appUserId = this.user.adid;
      item.question = question;
      item.answer = value;
      item.isRequired = isRequired;
      item.questionUsed = true;
      item.questionId = questionId;
      this.questionAnswered.push(item);
    }
  }

  get user() {
    Utils.mapToObject(this.$store.state.user, this.model);
    return this.$store.state.user;
  }

  get userProfile() {
    return AuthenticationContext.user
      ? AuthenticationContext.user.profile
      : null;
  }

  getUser() {
    this.userService.getCurrentUser().then((resp: User) => {
      let phone: any;
      this.$store.commit("set_user", resp);
      this.model = resp;
      if (resp.phoneRecovery) phone = resp.phoneRecovery.split("");

      if (
        !this.model.appUserRecoveryQuestions.length &&
        phone < 5 &&
        !this.model.secondEmail
      )
        this.isRecoveryAcountActive = true;
    });
  }

  validateIfIsAuthMethodActive() {
    //debugger;
    if (!this.user.phoneRecovery && !this.user.secondEmail) {
      if (this.user.appUserRecoveryQuestions.length > 0) {
        return;
      }
      if (
        (!this.isSmtpActive && !this.isTwilioActive) ||
        this.isAuthMethodQuestionActive
      ) {
        this.isRecoveryAcountActive = true;
        this.currentTab = 1;
        this.isFirsTime = false;
      } else if (
        this.isSmtpActive ||
        this.isTwilioActive ||
        this.isAuthMethodQuestionActive
      ) {
        this.isRecoveryAcountActive = true;
      }
    }
  }

  getOrganization() {
    //debugger;
    this.sdsadConnectorService
      .getOrganization()
      .then((resp: any) => {
        this.$store.commit(
          "set_isTwilioActive",
          resp.adAdminConnector.twilio !== null
            ? resp.adAdminConnector.twilio.isActive
            : false
        );
        this.$store.commit(
          "set_isSmtpActive",
          resp.adAdminConnector.smtp !== null
            ? resp.adAdminConnector.smtp.isActive
            : false
        );
      })
      .finally(() => {
        this.validateIfIsAuthMethodActive();
      });
  }

  get isSmtpActive() {
    return this.$store.state.isSmtpActive;
  }

  get isTwilioActive() {
    return this.$store.state.isTwilioActive;
  }
  public async getQuestions() {
    await this.sdsadConnectorService
      .getQuestions()
      .then((resp) => {
        //debugger;
        this.questions = resp;
        this.isAuthMethodQuestionActive = this.questions.some(
          (x: any) => x.isAuthMethodActive
        );
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  public validateEmailAndPhone(user: User) {
    if (user.secondEmail) {
      let result = user.secondEmail.includes("@");
      return result;
    } else if (user.phoneRecovery) {
      let phone = user.phoneRecovery.split("");
      if (phone.length > 4) {
        return true;
      }
    }
  }

  updateUserInfo() {
    if (this.model.secondEmail || this.model.phoneRecovery) {
      let result = this.validateEmailAndPhone(this.model);
      if (!result) {
        this.operationFailed(
          "You must complete at least one of the two fields"
        );
        this.isLoading = false;
        return;
      }
      //debugger
      this.userService.update(this.model.id, this.model).finally(() => {
        this.getUser();
        this.isLoading = false;
        this.isRecoveryAcountActive = false;
        this.operationSuccess();
      });
    }
  }
  saveRecoveryQuestion(data: any) {
    this.userService.saveRecoveryQuestion(data).then((resp) => {
      this.isLoading = false;
      this.isRecoveryAcountActive = false;
      this.operationSuccess();
      this.getUser();
    });
  }

  async update() {
    this.isLoading = true;
    if (this.questionAnswered.length) {
      try {
        if (this.questions.some((x: any) => x.isRequired && x.isActive)) {
          var result = await this.$validator.validateAll();
          if (result) {
            this.saveRecoveryQuestion(this.questionAnswered);
            return;
          }
        } else {
          this.saveRecoveryQuestion(this.questionAnswered);
          return;
        }
        throw this.$validator.errors.items.map((e: any) => e.msg);
      } catch (errors) {
        this.operationFailedErrors(errors);
      }
    }
    this.isLoading = false;
  }
}
