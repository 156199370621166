
















import { Component, Vue, Watch } from "vue-property-decorator";
import NavBar from "@/components/layout/navbar.vue";
import SideBar from "@/components/layout/sidebar.vue";
import UserService from "./services/user.service";
import PhoneMaskInput from "vue-phone-mask-input";
import User from './models/security/user.model';
import BaseRepository from './core/HttpResources/BaseRepository';
import BaseVue from './core/BaseVue';
import { warn } from 'vue-class-component/lib/util';

@Component({
  components: { NavBar, SideBar, PhoneMaskInput  }
})
export default class App extends BaseVue {
  public userService: UserService = new UserService();
  public  isRecoveryAcountActive: boolean = false;
  public model:User = new User();
  public comunicateThrougEmailOrPhone:boolean = false;
  public isLoading:boolean = false;
  isCardModalActive = true;



  get user(){
    return this.$store.state.user;
  }
}
