import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: JSON.parse(localStorage.getItem("user") || "{}") || {},
    criteria: "",
    isListMode: true,
    orderValue: null,
    isSmtpActive:false,
    isTwilioActive:false,
  },
  mutations: {
    set_user(state, user) {
      state.user = user;
    },
    set_isSmtpActive(state, value) {
      state.isSmtpActive = value;
    },
    set_isTwilioActive(state, value) {
      state.isTwilioActive = value;
    },
    set_isListMode(state, value) {
      state.isListMode = value;
    },
    set_criteria(state, value) {
      state.criteria = value;
    },
    set_orderBy(state, value) {
      state.orderValue = value;
    },
  },
  actions: {

  },
  modules: {

  },
});
