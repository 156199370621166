import BTableColumn from '@/models/base/BTableColumn.model';
import BTableConfigChecking from './BTableConfigChecking';
import BTableConfigDetailing from './BTableConfigDetailing';
import BTableConfigPagination from './BTableConfigPagination';
import BTableConfigSorting from './BTableConfigSorting';
import BTableConfigStyle from './BTableConfigStyle';
import BTableConfigSelection from './BTableConfigSelection';
import BaseHttpClient from '../HttpResources/BaseHttpClient';
import BaseRepository from '../HttpResources/BaseRepository';
import { RowTable } from '@/typings/RowTable';

export default class BTableConfig<T> {
    private _repository!: BaseRepository<T>;

    public controller!: string;
    public resource!: string;
    public whereParams!: string;
    public include!: string;
    public data: T[] = [];
    public columns: BTableColumn[];
    public loading: boolean;
    public enableDefaultColumns: boolean;//Enables Id, Fecha and Usuario columns
    public customRowKey: string;
    public activeActions: boolean;
    public loadOnMount: boolean;
    public showReload: boolean;
    public actions: ColumnActionConfig<T>;
    public customEmptyTemplate!: boolean;
    public emptyText: string;

//  SELECTION
    public selectable: boolean;
    public selection: BTableConfigSelection<T>;
    public focusable: boolean;

//  CHECKING
    public checkable: boolean;
    public checking: BTableConfigChecking<T>;

// DETAILING
    public detailed: boolean;
    public detailing: BTableConfigDetailing;

//  PAGINATION
    public paginated: boolean;
    public pagination: BTableConfigPagination;

// SORTING
    public sorting: BTableConfigSorting;

// STYLING
    public style: BTableConfigStyle;

//Methods
    public rowClass(row: Object, index: Number) { }
    public click!:(row: object) => void;
    public dblclick!:(row: object) => void;
    public rightClick!:(row: object) => void;
    public onLoad!:(data: T[]) => void;
    constructor() {
        this.columns = [];
        this.data = [];
        this.loading = false;
        this.customRowKey = 'id';
        this.activeActions = true;
        this.loadOnMount = true;
        this.emptyText = "No hay datos."
        this.actions = new ColumnActionConfig<T>();
        this.enableDefaultColumns = true; 
        this.showReload = true; 
        
        this.selectable = false;
        this.focusable = false;
        this.selection = new BTableConfigSelection<T>();

        this.checkable = false;
        this.checking = new BTableConfigChecking<T>();

        this.detailed = false;
        this.detailing = new BTableConfigDetailing();

        this.paginated = true;
        this.pagination = new BTableConfigPagination();

        this.sorting = new BTableConfigSorting();

        this.style = new BTableConfigStyle();
        this.reload = () => {};
    }
    public repository() {
        if (!this._repository)
            this._repository = new BaseRepository<T>(this.controller);

        return this._repository;
    }
    public setRows(rows: T[], callback?: Function){
        this.data = rows;
        if (callback) callback(this.data);
    }
    public addRows(rows: T[], callback?: Function) {
        rows.forEach(x=> this.data.push(x));
        if (callback) callback(this.data);
    }
    public insertRows(...rows: T[]) {
        rows.forEach(x => this.data.push(x));
    }
    public addRow(row: T, callback?: Function) {
        this.data.push(row);
        if (callback) callback(this.data);
    }
    public setColumns(columns: BTableColumn[], callback?: Function) {
        this.columns = columns;
        if (callback) callback(this.columns);
    }
    public addColums(columns: BTableColumn[], callback?: Function) {
        columns.forEach(x => this.columns.push(x));
        if (callback) callback(this.columns);
    }
    public insertColumns(...columns: BTableColumn[]) {
        columns.forEach(x => this.columns.push(x));
    }
    public addColum(columns: BTableColumn, callback?: Function) {
        this.columns.push(columns);
        if (callback) callback(this.columns);
    }
    public reload!:(callback?:Function)=>void;
}

class ColumnActionConfig<T>{
    public customTemplate: boolean;
    public props: BTableColumn;
    public showEdit: boolean;
    public showRemove:boolean;
    constructor(){
        this.customTemplate = false;
        this.showEdit = true;
        this.showRemove = true;
        this.props = new BTableColumn('btn-action','table.action');
        this.props.sortable = false;
        this.props.centered = true;
    }
    public edit!: (id: number, row: T, props: RowTable<T>) => void
    public remove!: (id: number, row: T, props: RowTable<T>) => void;
    public isEditableRow(row: Object): boolean {
        return true;
    };
    public isRemovebleRow(row: Object): boolean {
        return true;
    };
}