




















import { Component, Vue, Prop } from 'vue-property-decorator';
import './quickview.css'
@Component({})
export default class QuickviewComponent extends Vue {
  @Prop() readonly title!: string;
  @Prop() readonly customHeader!: boolean;
  @Prop() readonly show!: boolean;

  hide() {
    this.$emit('update:show', false);
    this.$emit('hide');
  }
}
