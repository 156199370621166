import Vue from 'vue';

//base components
import baseTable from '@/components/base/baseTable/baseTable.vue';
import baseSelect from '@/components/base/baseSelect/baseSelect.vue';
import quickview from '@/components/quickview/quickview.vue';

Vue.component('baseTable', baseTable);
Vue.component('baseSelect', baseSelect);
Vue.component('quickView', quickview);
