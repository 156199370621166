import BaseHttpClient from './BaseHttpClient';
import { AxiosResponse } from 'axios';
import HttpStatusCode from '@/enums/HttpStatusCode';
import ODataQueryOption from '@/models/base/ODataQueryOption.model';
import ApiQueryOption from '@/models/base/ApiQueryOption';

export default class BaseRepository<T> {
    private client: BaseHttpClient;
    constructor(public controller: string) {
        this.client = new BaseHttpClient(controller);
    }
    public async getById(id: number | string, params = new ApiQueryOption()): Promise<T>{
        try {
            return await this.client.getAsync(id.toString(), params);
        } catch (error) {
            throw error;
        }
    }
    public async get(params = new ODataQueryOption()): Promise<T[]> {
        try {
            return await this.client.getAsync<T[]>(String(), params)
        } catch (error) {
            throw error;
        }
    }

    public async create(entity: T, params?: object): Promise<T> {
        try {
            return await this.client.postAsync<T>(String(), entity, params);
        } catch (error) {
            var errors:any[] = [];
        
            if(Object.prototype.toString.call(error.data) === "[object String]"){
                errors.push(error.data);
            } else if (error.data && error.data.length > 0) {
                var errorMessages = error.data as any[];
                errorMessages.forEach(x => errors.push(x.errorMessage));
                throw errors;
            }else{
                errors.push(error);
            }
            throw errors;
        }
    }
    public async update(id: number, entity: T, params?: object): Promise<T> {
        try {
            return this.client.putAsync<T>(String(), id, entity, params);
        } catch (error) {
            var errors: any[] = [];
            if(Object.prototype.toString.call(error.data) === "[object String]"){
                errors.push(error.data);
            } else if (error.data && error.data.length > 0) {
                var errorMessages = error.data as any[];
                errorMessages.forEach(x => errors.push(x.errorMessage));
                throw errors;
            }else{
                errors.push(error);
            }
            throw errors;
        }
    }
    public async delete(id: number, params?: object): Promise<T> {
        try {
            return await this.client.deleteAsync<T>(String(), id, params);
        } catch (error) {
            throw error;
        }
    }
    public async byId(id: number, include: string = String()): Promise<T> {
        return this.one(`id eq ${id}`, include);
    }
    public async first(params = new ODataQueryOption()): Promise<T> {
        params.$top = 1;
        let result = await this.get(params) as any;
        return result.data[0];
    }
    public async one(where:string,include:string = String()): Promise<T> {
        let params = new ODataQueryOption();
        params.$top = 1;
        params.$filter = where;
        if (include)
        params.$expand = include
        let result = await this.get(params) as any;
        return result.data[0];
    }
    public async many(where: string, include = String(), count: number = 2000, order = 'id'): Promise<T[]> {
        let params = new ODataQueryOption();
        params.$top = count;
        params.$orderby = order;
        params.$filter = where;
        if (include)
            params.$expand = include
        return await this.get(params) as any;
    }
    public async single(where:string, params = new ODataQueryOption()): Promise<T> {
        params.$filter = where;
        params.$top = 1;
        let result = await this.get(params) as any;
        return result.data[0];
    }
    public async search(where: string, params = new ODataQueryOption()): Promise<T[]> {
        params.$filter = where;
        return this.get(params) as any;
    }
    public async macthAnd(where: T | any, params = new ODataQueryOption()): Promise<T[]> {
        let queryArry = [];
        for(let prop in where){
            if (where[prop] !== undefined)
            queryArry.push(`${prop}=${where[prop]}`);
        }
        params.$filter =queryArry.join('&&');
        return await this.get(params) as any;
    }
    public async macthOr(where: T | any, params = new ODataQueryOption()): Promise<T[]> {
        let queryArry = [];
        for (let prop in where) {
            if (where[prop] !== undefined)
                queryArry.push(`${prop}=${where[prop]}`);
        }
        params.$filter = queryArry.join('||');
        return await this.get(params) as any;
    }
    
}
