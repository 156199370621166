import BaseEntity from '../base/BaseEntity.model';

export default class AppUserRecoveryQuestion extends BaseEntity{
    public appUserId?:string | null;
    public  question ?:string | null;
    public  answer ?:string | null;
    public  questionUsed ?: boolean | null;
    public  questionId ?:number | null;
  public isRequired?:boolean;
  public isSameAnswer?:boolean | null;
    constructor(){
        super();
        this.init();
    }

    init(){
      this.appUserId = null;
      this.question = null;
      this.answer = null;
      this.isRequired = false;
      this.questionUsed = false;
      this.questionId = null;
      this.isSameAnswer = true;
    }
}