import { Component, Vue } from 'vue-property-decorator';
import BTableConfig from '@/core/BTableConfig/BTableConfig';
import BaseVue from '@/core/BaseVue';
import BaseRepository from '../HttpResources/BaseRepository';
import { RowTable } from '@/typings/RowTable';
@Component
export default class GenericBTable<T> extends BaseVue {
    protected tableConfig: BTableConfig<T>;
    protected repository!: BaseRepository<T>;
    
    constructor() {
        super();
        this.tableConfig = new BTableConfig<T>();
        this.tableConfig.actions.edit = this.edit;
        this.tableConfig.actions.remove = this.remove;
    }

    mounted() {
     
        this.repository = new BaseRepository(this.tableConfig.controller);

    }

    protected add() {
        this.$router.push(`${this.$router.currentRoute.path}/agregar`)
    }
    protected edit(id: number, data: T, props: RowTable<T>) {
        this.$emit('edit', data, props);
        this.$store.commit('setEditEntity', data);
        this.$router.push(`${this.$router.currentRoute.path}/editar/${id}`)
    }
    protected remove(id: number, data: T, props: RowTable<T>) {
        this.$buefy.dialog.confirm({
            title: 'Confirmación',
            message: '¿Desea eliminar este registro?',
            confirmText: 'Adelante!',
            cancelText: 'Cancelar',
            type: 'is-danger',
            size: 'is-medium',
            hasIcon: true,
            onConfirm: () => this.confirmRemove(id, data, props)
        })
    }

    protected async confirmRemove(id: number, data: T, props: RowTable<T>) {
        try {
            await this.repository.delete(id);
            this.operationSuccess('Registro eliminado!');
            if (this.tableConfig.reload)
                this.tableConfig.reload();

            this.$emit('remove', id, data, props);
        } catch (e) {
            this.operationFailed(e);
        }
    }
}