




































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import UserService from "@/services/user.service";
import BaseVue from "@/core/BaseVue";
import { AuthenticationContext } from "vue-adal"; // This will be populated with a valid context after initialization
import User from '@/models/security/user.model';
import PhoneMaskInput from "vue-phone-mask-input";
import AppUserRecoveryQuestion from '@/models/security/appUserRecoveryQuestion.model';
import SDSADConnectorService from '@/services/sdsadConnector.service';
import { Utils } from "@/core/Utils";

@Component({
  components: { PhoneMaskInput }
})
export default class RecoveryUserAccount extends BaseVue {
  @Prop() readonly show!: boolean;
  public sdsadConnectorService: SDSADConnectorService = new SDSADConnectorService();

  c_show = this.show != undefined ? this.show : false;
  userService = new UserService();
  public model:User = new User();
  public isLoading:boolean = false;
  public questions:any = [];
  public loading = false;
  public isAuthMethodQuestionActive = false;
  public questionTab  = 0;
  public currentTab:number = 0;
  public showPhone = false;

async mounted(){
  this.isLoading = true;
  await this.getQuestions();
  this.isLoading = false;
  this.model.phoneRecovery = this.$store.state.user.phoneRecovery;
}

  get isSmtpActive() {
    return this.$store.state.isSmtpActive;
  }

  get isTwilioActive() {
    return this.$store.state.isTwilioActive;
  }

   @Watch("currentTab")
    onCurrentTabChange(value:number){
      debugger;
        this.currentTab = value;
        if(value === 1){
          if(!this.questions.length)
          this.getQuestions();
        }
    }
  @Watch("show")
  onShowChange() {
     this.c_show = this.show;
     Utils.mapToObject(this.$store.state.user,this.model)
     this.showPhone = true;
  }

  hide() {
    this.$emit("update:show", false);
    this.$emit("hide");
    this.c_show = false;
  }

    async getQuestions() {
      this.isLoading = true;
    await this.sdsadConnectorService.getQuestions().then((resp) => {
     this.isAuthMethodQuestionActive =  resp.some((x:any) => x.isAuthMethodActive);
      resp.filter((x:any) => x.isActive).reduce((questions:any,currentValue:any,index:number) => {
            this.questions.push(
              this.$store.state.user.appUserRecoveryQuestions.find(
                (x:any) => x.questionId === currentValue.id) 
                ? this.$store.state.user.appUserRecoveryQuestions.find(
                  (x:any) => x.questionId === currentValue.id) : currentValue) as [];
       },[{}])
    }).finally(()=>{
      this.isLoading = false;
    })
  }

  saveAnswer(question: string, questionId: number,isRequired:boolean,id:number) {
    debugger;
    let item = new AppUserRecoveryQuestion();
    let inputValue: any;
    questionId =(questionId) ? questionId : id;
    let objectFinded = this.model.appUserRecoveryQuestions.find(
      (x:any) => x.questionId === questionId
    );
    let inputId = "input_id" + id;
    inputValue = document.getElementById(inputId);
    let value = inputValue.value;

    if (!objectFinded) {
      item.appUserId = this.user.adid;
      item.question = question;
      item.answer = value;
      item.isRequired = isRequired;
      item.questionUsed = true;
      item.questionId = questionId;
      this.model.appUserRecoveryQuestions.push(item);
    }
  }

  cancel() {
    this.hide();
  }
   public validateEmail(user:User){
    debugger;
  if(user.secondEmail){
    let result = user.secondEmail.includes("@")
      return result;
   }else if(user.phoneRecovery){
    let phone = user.phoneRecovery.split("");
  if(phone.length > 4){
      return true;
  }
   }
  }

  
  updateUserInfo(){
     if (this.model.secondEmail || this.model.phoneRecovery) {
      let result = this.validateEmail(this.model);
      if (!result) {
        this.operationFailed("You must complete at least one of the two fields");
        this.isLoading = false;
        return;
      }
      debugger;
      this.userService.update(this.model.id, this.model).finally(() => {
                 this.isLoading = false;
                 this.operationSuccess();
                });
     }
  }
  updateRecoveryQuestion(data:any){
     this.userService.updateRecoveryQuestion(data).then(resp => {
                this.isLoading = false;
                this.operationSuccess();
              })
  }
 async update() {
    debugger;
    this.isLoading = true;
    if (this.model.appUserRecoveryQuestions) {
      try {
        debugger;
          if (this.model.appUserRecoveryQuestions.some((x:any) => x.isRequired)) {
            var result = await this.$validator.validateAll();
            if (result) {
             this.updateRecoveryQuestion(this.model.appUserRecoveryQuestions);
              return;
            }
          } else {
            this.updateRecoveryQuestion(this.model.appUserRecoveryQuestions);
            return;
          }
        throw this.$validator.errors.items.map((e: any) => e.msg);
      } catch (errors) {
        debugger
        this.operationFailedErrors(errors);
      }
    }
    this.isLoading = false;
  }

  

}
