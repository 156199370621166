

































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AuthenticationContext } from "vue-adal"; // This will be populated with a valid context after initialization
import ChangePassword from "@/components/password/changePassword.vue";
import RecoveryUserAccount from "../recoveryUserAccount/recoveryUserAccount.vue";
  import introJs from 'intro.js';
import SDSADConnectorService from '@/services/sdsadConnector.service';
@Component({
  components: { ChangePassword, RecoveryUserAccount },
})
export default class NavBar extends Vue {
  public sdsadConnectorService: SDSADConnectorService = new SDSADConnectorService();
isClicked = false;
  showChangePassword = false;
  name = "";
  isAuthMethodQuestionActive = false;
  hideButton = false;
  isLoading = false;
isSmtpActive = false;
isTwilioActive = false;
isMenuActive = false;
  public orderBy:number = 0;
  public showChangeOptionToRecoveryAccount: boolean = false;
 async beforeCreated(){
   await  this.getQuestions();
   await this.getOrganization();
 }
  created(){
    this.isLoading = true;
    if(this.user.id){
      this.$router.push("/home");
    }
    this.isLoading = false;
  }
  get userProfile() {
    return AuthenticationContext.user
      ? AuthenticationContext.user.profile
      : null;
  }

   showAllSteps(){
    introJs().setOption('showProgress', true)
     .setOption("showProgress", true)
      .setOption("nextLabel",this.translateSx("button.next"))
      .setOption("prevLabel",this.translateSx("button.back"))
      .setOption("skipLabel",this.translateSx("button.finish")).start();
  }

   showSpecificElement(element: number) {
    introJs()
      .setOption("showProgress", true)
      .setOption("nextLabel",this.translateSx("button.next"))
      .setOption("prevLabel",this.translateSx("button.back"))
      .setOption("skipLabel",this.translateSx("button.finish"))
      .goToStepNumber(element)
      .start();
  }

   translateSx(value:string){
   var filters = this.$options.filters as any;
   return filters.sxTranslate(value);
 }
   async getQuestions() {
    await this.sdsadConnectorService.getQuestions().then((resp) => {
     this.isAuthMethodQuestionActive =  resp.some((x:any) => x.isAuthMethodActive);
    });
  }

    async getOrganization(){
   await this.sdsadConnectorService.getOrganization().then( resp =>{
    this.isSmtpActive =  resp.adAdminConnector.smtp.isActive
    this.isTwilioActive =  resp.adAdminConnector.twilio.isActive
        if(!this.isSmtpActive && !this.isTwilioActive && !this.isAuthMethodQuestionActive){
            this.hideButton = true;
        }
    });
  }

 get user() {
    return this.$store.state.user;
  }
  onClickIsListMode() {
    this.isClicked = false;
    this.$store.commit("set_isListMode", true);
  }
  isNormalMode() {
    this.isClicked = true;
    this.$store.commit("set_isListMode", false);
  }
  searchApp(value: string) {
    this.$store.commit("set_criteria", value);
  }
  orderByValue(value: number) {
    this.$store.commit("set_orderBy", value);
  }

  logOut(){
    var self = this as any;
    localStorage.removeItem("adal.idtoken");
    self.$adal.logout();
  }
}
