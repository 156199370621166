

































































































































































import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";
import { Watch } from "vue-property-decorator";
import BaseHttpClient from "@/core/HttpResources/BaseHttpClient";
import BTableColumn from "@/models/base/BTableColumn.model";
import BTableConfig from "@/core/BTableConfig/BTableConfig.ts";
import { AxiosResponse, AxiosPromise } from "axios";
import HttpStatusCode from "@/enums/HttpStatusCode";
import { BTableColumnType } from "@/enums/BTableColumnType";
import moment from "moment";
import "./baseTable.css";
import { Utils } from "@/core/Utils";
import ODataQueryOption from "@/models/base/ODataQueryOption.model";
import { RowTable } from "@/typings/RowTable";
//import PermissionAccess from '@/models/security/permissionAccess.model';
@Component({})
export default class BaseTableComponent extends Vue {
  @Prop() public config!: BTableConfig<object>;

  public client = new BaseHttpClient(this.config.controller);

  created() {
    if (this.config.enableDefaultColumns) this.insertDefaultColumns();

    this.config.reload = this.getData;
  }

  getItemName(key: any) {
    //return Helpers.Filters.Translate(key);
    return key;
  }

  insertDefaultColumns() {
    // var id = new BTableColumn("id", "Id");
    //id.order = -1;
    let fecha = new BTableColumn("createdDate", "Date");
    fecha.type = BTableColumnType.DateTime;
    fecha.order = 200;
    // let usuario = new BTableColumn("createdBy", "Usuario");
    // usuario.order = 201;
    // usuario.customTemplate = true;
    this.config.insertColumns(fecha);
  }
  mounted() {
    this.sortColumns();
    if (this.config.loadOnMount) this.getData();
  }
  getData(callback?: Function) {
    this.config.loading = true;
    this.client.get(
      this.config.resource || String(),
      this.queryParams(),
      (response: AxiosResponse) => {
        let data = (response.data.data as any[]) || [];
        this.config.setRows(data);
        //this.config.addRow(data[0]);
        let count = response.data.count;
        if (this.config.pagination.backend)
          this.config.pagination.total = count;

        this.$emit("load-success", response, this.config);
        if (this.config.onLoad) {
          this.config.onLoad(data);
        }
        if (callback) callback();
      },
      (response: AxiosResponse) => {
        this.$emit("load-error", response, this.config);
      },
      (response: AxiosResponse) => {
        this.config.loading = false;
        this.$emit("load-finally", response, this.config);
      }
    );
  }
  edit(id: number, row: object, props: RowTable<object>) {
    this.$emit("edit", row, props);
  }
  remove(id: number, row: object, props: RowTable<object>) {
    this.$emit("delete", id, row, props);
  }
  sortColumns() {
    this.config.columns = this.config.columns.sort(
      (a: BTableColumn, b: BTableColumn) => {
        if (a.order === undefined) a.order = this.config.columns.indexOf(a);

        if (b.order === undefined) b.order = this.config.columns.indexOf(b);

        return a.order - b.order;
      }
    );
  }
  getValueTime(row: any, value: string) {
    let val = this.getFieldValue(row, value);
    return moment(val).format("LT");
  }
  getValueDate(row: any, value: string) {
    let val = this.getFieldValue(row, value);
    return moment(val).format("L");
  }
  //  Computed
  queryParams(): ODataQueryOption {
    let instace = new ODataQueryOption();
    instace.$orderby =
      this.config.sorting.field +
      (this.config.sorting.order === "desc" ? " desc" : "");
    instace.$skip =
      (this.config.pagination.currentPage - 1) * this.config.pagination.perPage;
    instace.$top = this.config.pagination.perPage;
    instace.$filter = this.config.whereParams;
    instace.$expand = this.config.include;

    if (!instace.$filter) delete instace.$filter;
    if (!instace.$expand) delete instace.$expand;
    return instace;
  }

  get selectableColumn() {
    return this.config.selectable ? this.config.selection.selected : null;
  }
  set selectableColumn(object: any) {
    this.config.selection.selected = object;
  }

  //  WATCHES
  @Watch("config.columns", { deep: true })
  onColumnsChange(columns: any[]) {
    this.$emit("columns-change", columns);
  }
  @Watch("config.data", { deep: true })
  onRowsChange(data: any[]) {
    this.$emit("rows-change", data);
  }

  //  SELECTION
  public select(row: object, oldRow: object) {
    if (this.config.selectable === false) {
      this.config.selection.selected = null;
      return;
    }

    if (!this.config.selection.change) {
      this.$emit("select", row, oldRow);
    } else this.config.selection.change(row, oldRow);
  }

  //  DETAILS
  public openRow(row: object) {
    if (!this.config.detailing.open) {
      this.$emit("details-open", row);
    } else this.config.detailing.open(row);
  }
  public closeRow(row: object) {
    if (!this.config.detailing.close) {
      this.$emit("details-close", row);
    } else this.config.detailing.close(row);
  }

  //  PAGINATION
  public pageChange(page: number) {
    if (!this.config.pagination.change) {
      this.$emit("page-change", page);
      this.config.pagination.currentPage = page;
      if (this.config.paginated && this.config.pagination.backend)
        this.getData();
    } else this.config.pagination.change(page);
  }

  //  SORTING
  public sort(field: string, order: string) {
    if (!this.config.sorting.change) {
      this.config.sorting.field = field;
      this.config.sorting.order = order;
      this.$emit("sort", field, order);

      if (this.config.sorting.backend) this.getData();
    } else this.config.sorting.change(field, order);
  }

  //  CHECKING
  public check(list: object[], row: object) {
    if (!this.config.checking.check) {
      this.$emit("check", list, row);
    } else this.config.checking.check(list, row);
  }
  public checkAll(list: object[]) {
    if (!this.config.checking.checkAll) {
      this.$emit("checkAll", list);
    } else this.config.checking.checkAll(list);
  }

  //  CLICKING
  public click(row: object) {
    if (!this.config.click) {
      this.$emit("click", row);
    } else this.config.click(row);
  }
  public dblclick(row: object) {
    if (!this.config.dblclick) {
      this.$emit("dblclick", row);
    } else this.config.dblclick(row);
  }
  public rightClick(row: object) {
    if (!this.config.rightClick) {
      this.$emit("rightClick", row);
    } else this.config.rightClick(row);
  }

  getFieldValue(row: any, field: string) {
    return Utils.getValueFromPath(row, field);
  }
}
