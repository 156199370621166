import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';
import BaseView from '../views/base.vue';
import PrincipalScreen from '../modules/principalScreen/principalScreen.vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/',
    name: 'PrincipalScreen',
    component: PrincipalScreen,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/key-value",
    name: "Key Value",
    component: BaseView,
    meta: {
      requireAuth: true
    },
    children: [
      {
        path: "/key-value",
        name: "Listado",
        component: () => import(/* webpackChunkName: "about" */ '../modules/example/keyvalue/table.vue'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: "/key-value/agregar",
        name: "Agregar",
        component:  () => import(/* webpackChunkName: "about" */ '../modules/example/keyvalue/add.vue'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: "/key-value/editar/:id",
        name: "Editar",
        component:  () => import(/* webpackChunkName: "about" */ '../modules/example/keyvalue/edit.vue'),
        meta: {
          requireAuth: true
        }
      }
    ]
  },
  {
    path: '/forgotPassword',
    name: 'Forgot Password',
    component: () => import( '../modules/recoverynPassword/forgotPassword.vue'),
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/forgotPassword/:id/waysTorecoveryPassword',
    name: 'Way To Recovery Password',
    component: () => import( '../modules/recoverynPassword/wayToRecoveryPassword.vue'),
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/forgotPassword/:email/waysTorecoveryPassword/recoveryn/:id/password',
    name: 'Recoveryn Password',
    component: () => import( '../modules/recoverynPassword/recoverynPassword.vue'),
    meta: {
      requireAuth: false
    }
  },
];

const routesNotAuthenticated: RouteConfig[] = routes.filter((x: RouteConfig) => !x.meta.requireAuth);
const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
export { routesNotAuthenticated };