import Vue from "vue";
import BTableColumnFilter from "@/filters/BTableColumnFilter";
import moment from 'moment';

Vue.filter("bTableColumnValue", BTableColumnFilter.Value);

Vue.filter("date", (value: string) => {return moment(value).format("L")});
Vue.filter("time", (value: string) => moment(value).format("LT"));
Vue.filter("bool", (value: boolean) => (value ? "Sí" : "No"));
Vue.filter("empty", (value: string) => (value ? value : "N/A"));
Vue.filter("suspensivo",
  function (texto: any) {
    if (texto && texto.length > 110) return texto.substring(0, 29) + " ...";
    else return texto
  });