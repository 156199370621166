export default class ODataQueryOption {
    public $expand: string | undefined = undefined;
    public $filter: string | undefined = undefined;
    public $orderby: string | undefined = undefined;
    public $top: number | undefined = Number.parseFloat(process.env.VUE_APP_PAGE_SIZE as any) || 10;
    public $skip: number | undefined = undefined;
    public $select: string | undefined = undefined;
    public $search: string | undefined = undefined;
    public $count: boolean = true;
    constructor() {
    }
} 