export class Utils {

    static generateZeros(quantity: number) {
        let string = "";
        for (var i = 1; i < quantity; i++) {
            string += "0";
        }
        return string;
    }
    static newGUID() {
        var d = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    }

    static normalizeNFD(str: string) {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
    }
    static camelize(str: string) {
        str = Utils.normalizeNFD(str);
        return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
            if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
            return index == 0 ? match.toLowerCase() : match.toUpperCase();
        });
    }
    static mapToObject<T>(fromObject: any, toObject: T | any, config: any = null): T {
        let dateIsoRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/i;
        fromObject = JSON.parse(JSON.stringify(fromObject));
        config = config || { ignore: [] };

        for (let key in fromObject) {
            if (fromObject.hasOwnProperty(key)) {
                if (!config.ignore.length || !config.ignore.some((x: any) => x === key)) {
                    toObject[key] = fromObject[key];
                    if (dateIsoRegex.test(toObject[key])) {
                        toObject[key] = new Date(toObject[key]);
                    }
                }
            }
        }
        return toObject;
    }

    static mapSimpleObject<T>(fromObject: any, toObject: T | any, config: any = null): T {
        fromObject = JSON.parse(JSON.stringify(fromObject));
        config = config || { ignore: [] };

        for (let key in fromObject) {
            if (fromObject.hasOwnProperty(key)) {
                if (!config.ignore.length || !config.ignore.some((x: any) => x === key)) {
                    toObject[key] = fromObject[key];
                }
            }
        }
        return toObject;
    }

    static isCedula(ced: string) {
        if (!ced)
            return false;
        if (ced.length > 13)
            return false;

        if (ced.length > 11 && ced.length < 14 && !ced.includes('-'))
            return false;

        var c = ced.replace(/-/g, '');
        var cedula = c.substr(0, c.length - 1);
        var verificador = c.substr(c.length - 1, 1);
        var suma = 0;
        var cedulaValida = 0;
        if (ced.length < 11) { return false; }
        for (let i = 0; i < cedula.length; i++) {
            let mod = 0;
            if ((i % 2) == 0) { mod = 1 } else { mod = 2 }
            let res = (Number.parseInt(cedula.substr(i, 1)) * mod).toString();
            if (Number.parseInt(res) > 9) {
                res = res.toString();
                let uno = res.substr(0, 1);
                let dos = res.substr(1, 1);
                res = eval(uno) + eval(dos);
            }
            suma += eval(res);
        }
        let el_numero = (10 - (suma % 10)) % 10;
        if (el_numero.toString() == verificador && cedula.substr(0, 3) != "000")
            cedulaValida = 1;
        else
            cedulaValida = 0;
        return !!cedulaValida;
    }

    static getValueFromPath(obj: any, path: string) {
        return path.split(".").reduce((prev, curr) => prev ? prev[curr] : null, obj || self);
    }

    static selectMany(f: any) {
        return function (acc: any, b: any) {
            return acc.concat(f(b))
        }
    }

    static downloadURI(uri: string, name: string) {
        var link = document.createElement("a");
        link.target = "_blank";
        link.download = name;
        link.href = uri;
        link.click();
    }
}
