import Vue from 'vue';
import axios, { AxiosRequestConfig } from 'axios';
export default class BaseApi extends Vue {
    public baseUrl: string;
    public apiUrl: string;
    public headers!: any;
    constructor(controllerName: string) {
        super();
        this.baseUrl = process.env.VUE_APP_API_URL as any;
        this.apiUrl = `${this.baseUrl}${controllerName}/`;
        this.headers = {};
    }
    created(){
     //   this.headers.Authorization = `Bearer ${localStorage.getItem("token")|| ''}`
    }
    public genericRequest<T>(method: any, url: string = '', data: T | T[], params?: object,
     successCallback: (...params: any) => void = (response) => {
    }, errorCallback: (...params: any) => void = (error) => {
        }, finallyCallback: (...params: any) => void = () => { }, headers: object = this.headers) {

       // this.headers.Authorization = `Bearer ${localStorage.getItem("token") || ''}`

        const config: AxiosRequestConfig = {
            method,
            url,
            data,
            params,
            headers
        };
        var self = this as any;
        var request = self.$http ;
        if(request){
            request(config).then(successCallback).catch(errorCallback).then(finallyCallback);
        }
    }
    public request<T>(method: string, action: string = '', data: T | T[], params?: object, successCallback?: (response: any) => void, errorCallback?: (...params: any) => void, finallyCallback?: (...params: any) => void, headers?: object) {
        this.genericRequest(method, this.apiUrl + action, data, params, successCallback, errorCallback, finallyCallback, headers);
    }
    public do<T>(method: string, controllerNameAndAction: string = '', data: T | T[], params: object, successCallback?: (response: any) => void, errorCallback?: (...params: any) => void, finallyCallback?: (...params: any) => void) {
        this.genericRequest(method, this.baseUrl + controllerNameAndAction, data, params, successCallback, errorCallback, finallyCallback);
    }
}
