














































































































import { Component, Vue } from "vue-property-decorator";
import SDSADConnectorService from "../../services/sdsadConnector.service";
import BaseVue from "../../core/BaseVue";
import { Utils } from "../../core/Utils";

@Component({
  components: {},
})
export default class PrincipalScreen extends BaseVue {
  public sdsadConnectorService: SDSADConnectorService = new SDSADConnectorService();

  isCardModalActive = true;
  isAdActive = false;
  isLoanding = false;
  token = true;
  async created() {
    this.continueUpdateToken();
    if (this.user.id) {
      this.$router.push("/home");
    }
  }

  forgotPassword() {
    this.$router.push("/forgotPassword");
  }

  goToLogin() {
    this.$router.push("/home");
  }

  get user() {
    return this.$store.state.user;
  }

  continueUpdateToken() {
    setTimeout(() => {
      if(localStorage.getItem("adal.idtoken")){
        this.token = true;
      }else{
        this.token = false;
      }
    }, 500);
  }
}
