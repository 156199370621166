




























































import { Component, Vue, Watch } from "vue-property-decorator";
import SDSADConnectorService from "../../services/sdsadConnector.service";
import BaseVue from "../../core/BaseVue";
import GenericBTable from "../../core/Generics/GenericBTable";
import ApplicationConnector from "../../models/ApplicationConnector/applicationConnector.mode";
import BTableColumn from "@/models/base/BTableColumn.model";

@Component({
  components: {},
})
export default class ApplicationComponent extends GenericBTable<
  ApplicationConnector
> {
  sdsadConnectorService: SDSADConnectorService = new SDSADConnectorService();

  public applications = [];
  isLoading: boolean = false;
  loading: boolean = false;

  created() {
    //debugger;
    this.formatColumns();
    this.isLoading = true;
    this.orderByRecently();
    this.isLoading = false;
     
  }
  formatColumns() {
    let name = new BTableColumn("name", "Nombre", false);
    let acronym = new BTableColumn("acronym", "Acronym", false);
    let description = new BTableColumn("description", "Description", false);
    let logoUrl = new BTableColumn("logoUrl", "Logo", false);
    this.tableConfig.actions.customTemplate = true;
    name.width = "30%";
    acronym.width = "30%";
    description.width = "30%";
    description.customTemplate = true;
    logoUrl.customTemplate = true;
    this.tableConfig.enableDefaultColumns = false;
    this.tableConfig.activeActions = false;
    this.tableConfig.insertColumns(name, acronym,description,logoUrl);
  }
  goToUrl(url: string) {
    window.open(url, "_blank");
  }

  get isModeListChange() {
    return this.$store.state.isListMode;
  }

   get searchApp() {
    return this.$store.state.criteria;
  }

   @Watch("$store.state.criteria")
    onCriteriaChange(value:string){
      this.loading = true;
      if(value){

           this.sdsadConnectorService.getApplicationsByCriteria(value).then(resp =>{
         this.applications = resp;
         this.tableConfig.data = resp;
       }).finally(()=>{
         this.loading = false;
       })
      }else{
         this.sdsadConnectorService
      .getApplications()
      .then((resp) => {
        this.applications = resp;
        this.tableConfig.data = resp;
      })
      .finally(() => {
        setTimeout(() => {
          this.loading = false;
        }, 200);
      });
      }
    }

    @Watch("$store.state.orderValue")
      onOrderValueChange(value:number){
        //debugger;
          if(value === 1){
            this.orderByName();
          }else if(value === 2){
            this.orderByAcronym();
          }
          else if(value === 3){
            this.orderByRecently();
          }

      }


    orderByName(){
        this.sdsadConnectorService.getApplicationsOrderByName().then(resp =>{
          this.tableConfig.data = resp;
          this.applications = resp;
        })
    }

   orderByAcronym(){
      this.sdsadConnectorService.getApplicationsOrderByAcronym().then(resp =>{
          this.tableConfig.data = resp;
          this.applications = resp;
        })
    }

    orderByRecently(){
          this.sdsadConnectorService.getApplicationsOrderByRecently().then(resp =>{
          this.tableConfig.data = resp;
          this.applications = resp;
        })
    }

}
