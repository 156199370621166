import  ODataQueryOption from '@/models/base/ODataQueryOption.model';
import BaseApi from "@/core/HttpResources/BaseApi";
import { AxiosError } from 'axios';
import HttpStatusCode from '@/enums/HttpStatusCode';
import ApiQueryOption from '@/models/base/ApiQueryOption';

export default class BaseHttpClient {
    private client: BaseApi;
    private headers!: Headers;
    constructor(controller:string, private options = new ApiQueryOption()) {
        this.client = new BaseApi(controller);
        this.headers = this.client.headers;
    }
    public serHeader(key:string, value:string){
        this.headers.set(key, value);
    }
    public async getAsync<T>(resource: string = '', params?: object): Promise<T> {
        return new Promise<T>((resolve, reject) => this.client.request('get', resource, null, params || this.options, (response) => resolve(response.data), (result: AxiosError) => {
            if (result.response) {
                reject(result.response);
                return;
            }
            reject(result);
        }, ()=>{}))
    }
    public async postAsync<T>(resource: string = '', data: T|any, params = Object()): Promise<T> {
        return new Promise<T>((resolve, reject) => this.client.request('post', resource, data, params, (response) => resolve(response.data), (result:AxiosError)=>{
            if(result.response){                
                reject(result.response);
                return;
            }
            reject(result);
        }, () => { }))
    }
    public async putAsync<T>(resource: string = '', id: number, data: T, params?: object): Promise<T> {
        let route = resource ? resource + `/${id}` : `${id}`;
        return new Promise<T>((resolve, reject) => this.client.request('put', route, data, params || this.options, (response) => resolve(response.data), (result: AxiosError) => {
            if (result.response) {
                reject(result.response);
                return;
            }
            reject(result);
        }, () => { }))
    }
    public async deleteAsync<T>(resource: string = '', id: number, params?: object) {
        let route = resource ? resource + `/${id}` : `${id}`;
        return new Promise<T>((resolve, reject) => this.client.request('delete', route, null, params || this.options, (response) => resolve(response.data), (result: AxiosError) => {
            if (result.response) {
                reject(result.response);
                return;
            }
            reject(result);
        }, () => { }))
    }
    public get(resource: string = '', params?: object, successCallback?: (response: any) => void, errorCallback?: (...params: any) => void, finallyCallback?: (...params: any) => void){
        this.client.request('get', resource, null, params || this.options, successCallback, errorCallback, finallyCallback, this.headers)
    }
    public odata(params?: object | ODataQueryOption, successCallback?: (response: any) => void, errorCallback?: (...params: any) => void, finallyCallback?: (...params: any) => void) {
        this.client.request('get', 'odata', null, params, successCallback, errorCallback, finallyCallback, this.headers)
    }
    public post(resource: string = '', data: object, params?: object,successCallback?: (response: any) => void, errorCallback?: (...params: any) => void, finallyCallback?: (...params: any) => void) {
        this.client.request('post', resource, data, params || this.options, successCallback, errorCallback, finallyCallback,this.headers)
    }
    public put(resource: string = '', id: number, data: object, params?: object, successCallback?: (response: any) => void, errorCallback?: (...params: any) => void, finallyCallback?: (...params: any) => void){
        let route = resource ? resource + `/${id}` : `${id}`;
        this.client.request('put', route, data, params || this.options, successCallback, errorCallback, finallyCallback, this.headers)
    }
    public delete(resource: string = '', id: number, params?: object, successCallback?: (response: any) => void, errorCallback?: (...params: any) => void, finallyCallback?: (...params: any) => void) {
        let route = resource ? resource + `/${id}` :`${id}`;
        this.client.request('delete', route, null, params || {}, successCallback, errorCallback, finallyCallback,this.headers)
    }
}
