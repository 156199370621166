import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Utils } from './Utils';
@Component
export default class BaseVue extends Vue {
    constructor() {
        super();
     //   this.$validator.localize('es');
    }
    get user(){
        return this.$store.state.user;
    }
    operationSuccess(message: string = "Operación exitosa!", duration = 2000) {
        this.$buefy.toast.open({
            message: message,
            type: 'is-success',
            duration: duration
        });
    }
    operationPublish(message: string = "Convocatoria publicada con éxito!", duration = 2000) {
        this.$buefy.toast.open({
            message: message,
            type: 'is-success',
            duration: duration
        });
    }
    noPublish(message: string = "Convocatoria rechazada con éxito!", duration = 2000) {
        this.$buefy.toast.open({
            message: message,
            type: 'is-success',
            duration: duration
        });
    }

    operationFailed(message: string = "Operación fallida!", duration = 4000) {
        this.$buefy.toast.open({
            message: message,
            type: 'is-danger',
            position: "is-top-right",
            duration: duration
        });
    }
    operationNoAllowed(message: string = "Operación no permitida!", duration = 4000) {
        this.$buefy.toast.open({
            message: message,
            type: 'is-warning',
            duration: duration,
            position: "is-bottom",
        });
    }

    operationFailedErrors(errors: string[], queue = false, duration = 4000, type = "is-danger") {
        errors.forEach(e => this.$buefy.toast.open({
            message: e,
            duration: duration,
            queue: queue,
            type: type,
            position: "is-top-right"
        }))
    }

    copy(obj: any) {
        return JSON.parse(JSON.stringify(obj));
    }

    map<T>(from: any) {
        return Utils.mapSimpleObject<T>(from, {});
    }

    equals(from: any, to: any): boolean {
        var result = false;
        for (let key in from) {
            if (to.hasOwnProperty(key)) {
                if (to[key] === from[key])
                    result = true;
                else {
                    result = false;
                    break;
                }
            } else
                result = false;
        }

        return result;
    }
}
