import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VeeValidate from 'vee-validate';

import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import './assets/css/materialdesignicons.min.css';
import 'material-icons/iconfont/material-icons.css';
import './css/style.css'
import './css/custom-style.css'
import './globalFilters';
import './globalcomponents'
import '@/config/vue-adal/adal.config';

// require styles
import 'bulma-quickview/dist/css/bulma-quickview.min.css';
import 'slm-select/dist/slm-select.min.css'
import SlmSelect from 'slm-select'

Vue.use(VeeValidate);

Vue.use(Buefy);

Vue.config.productionTip = false;

Vue.use(SlmSelect, {
  applicationServer: 'https://portalidiomas-api.azurewebsites.net',
  applicationCode: 'f8524736-0920-4120-99d0-d466b511c7d4',
  fallbackLocale: 'es',
})


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
